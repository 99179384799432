import React, { useState } from "react";

export default function Input({ onSend, onFormSubmit, formSubmitted, userData }) {
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = (e) => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (name.trim() && email.trim()) {
      onFormSubmit(name, email);
    }
  };

  return (
    <div>
      {!formSubmitted && !userData.isLoggedIn? (
        <div className="input-container">
          <form className="form-container" onSubmit={handleFormSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="chatbot-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="chatbot-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-submit">
              <button type="submit" className="submit-button">Start Chat</button>
            </div>
          </form>
        </div>
      ) : (
        <div className="input">
          <form onSubmit={handleSend}>
            <input
              type="text"
              onChange={handleInputChange}
              value={text}
              placeholder="Enter your message here"
            />
            <button>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 500 500"
              >
                <g>
                  <g>
                    <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                  </g>
                </g>
              </svg>
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
