import React, { useEffect, useState } from "react";

export default function BotMessage({ fetchMessage, text }) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function getMessage() {
      if (text) {
        setMessage(text);
      } else if (typeof fetchMessage === "function") {
        try {
          const response = await fetchMessage();
          setMessage(response);
        } catch (error) {
          console.error("Error fetching the message:", error);
          setMessage("Sorry, there was an error fetching the message.");
        }
      } else {
        console.error("No message source provided to BotMessage.");
        setMessage("No message to display.");
      }
    }
    getMessage();
  }, [fetchMessage, text]);

  return <div className="bot-message">{message}</div>;
}
