/* global chatbotOptions */

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Cookies from "js-cookie";

import BotMessage from "./components/BotMessage";
import UserMessage from "./components/UserMessage";
import Messages from "./components/Messages";
import Input from "./components/Input";
import Header from "./components/Header";

import API from "./ChatbotAPI";

import "./styles.css";

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [chatEnded, setChatEnded] = useState(false);

  const backgroundColor = chatbotOptions.bgcolor;
  const textColor = chatbotOptions.textColor;
  const chatbotWidth = chatbotOptions.chatbotWidth;

  // Set cookie name and duration (10 minutes)
  const cookieName = "cv_id";
  const cookieDuration = 10 * 60 * 1000; // 10 minutes in milliseconds
  const promptDuration = 5 * 60 * 1000; // 5 minutes before expiry

  useEffect(() => {
    async function loadWelcomeMessage() {
      if (chatbotOptions.userData.isLoggedIn) {
        // const formData = `My name is ${chatbotOptions.userData.userName} and my email is ${chatbotOptions.userData.userEmail}, I need some support.`;
        const userData = {
            "email": chatbotOptions.userData.email,
            "firstname": chatbotOptions.userData.firstname,
            "lastname": chatbotOptions.userData.lastname,
        }

        const formData = {
          'query_text' : `My name is ${chatbotOptions.userData.firstname} ${chatbotOptions.userData.lastname} and my email is ${chatbotOptions.userData.email}, I need some support.`
        }
        
        // Log the message
        console.log(formData);
        
        // Send this data to the chatbot
        const chatbotResponse = await API.GetChatbotResponse(formData, userData);
        
        // Display both the user message and the chatbot's response
        setMessages([
          <BotMessage key={1} text={chatbotResponse} />,
        ]);
      } else {
        setMessages([
          <BotMessage
            key="0"
            fetchMessage={async () => await API.GetChatbotResponse("hi")}
          />,
        ]);
      }
    }
    loadWelcomeMessage();

    // Get cookie value and expiration time
    // const cookieValue = Cookies.get('cv_id');
    // if (cookieValue) {
    //   const expirationTime = 10 * 60 * 1000; // 10 minutes in milliseconds
    //   const promptTime = expirationTime - 5 * 60 * 1000; // 5 minutes before expiration

    //   // Prompt 5 minutes before cookie expires
    //   const promptTimeout = setTimeout(() => {
    //     setMessages(prevMessages => [
    //       ...prevMessages,
    //       <BotMessage key={prevMessages.length} text={"Your session will expire in 5 minutes. Do you need anything?"} />
    //     ]);
    //   }, promptTime);

    //   // Handle cookie expiration
    //   const expirationTimeout = setTimeout(() => {
    //     setMessages(prevMessages => [
    //       ...prevMessages,
    //       <BotMessage key={prevMessages.length} text={"The chat has ended."} />
    //     ]);
    //     setChatEnded(true); // Set chat as ended
    //   }, expirationTime);

    //   // Clear timeouts on unmount
    //   return () => {
    //     clearTimeout(promptTimeout);
    //     clearTimeout(expirationTimeout);
    //   };
    // }
  }, []);

  const send = async (text) => {
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={async () => await API.GetChatbotResponse(text)}
      />
    );
    setMessages(newMessages);
  };

  const handleFormSubmit = async (userName, userEmail) => {
    // setName(userName);
    // setEmail(userEmail);
    // setFormSubmitted(true);
    // const welcomeMessage = `Welcome ${userName}! How can I assist you today?`;
    // setMessages([<BotMessage key="0" text={welcomeMessage} />]);
    setName(userName);
    setEmail(userEmail);
    setFormSubmitted(true);

    // Send a message to the chatbot including the name and email
    const formData = `My name is ${userName} and my email is ${userEmail}, I need some support.`;
    
    // Get the chatbot's response based on the submitted name and email
    const chatbotResponse = await API.GetChatbotResponse(formData);

    // Add the chatbot's response to the messages
    const newMessages = [
      <BotMessage key={messages.length + 1} text={chatbotResponse} />
    ];

    setMessages(newMessages);
  };

  // Apply the styles dynamically
  const chatbotStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: chatbotWidth,
  };

  return (
    <div className="chatbot" style={chatbotStyle}>
      <Header />
      <Messages messages={messages} />
      <Input
        onSend={send}
        onFormSubmit={handleFormSubmit}
        formSubmitted={formSubmitted}
        userData={chatbotOptions.userData}
      />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Chatbot />, rootElement);
