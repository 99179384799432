/* global chatbotOptions */

import axios from "axios";
axios.defaults.withCredentials = true;

const API = {
  GetChatbotResponse: async (message, logged_in_info = {}) => {
    let env = process.env.REACT_APP_CURRENT_ENV || "prod";
    let url = '';
    if(chatbotOptions.sandbox){
      // env = process.env.REACT_APP_CURRENT_ENV || "dev";
      url = 'https://botapidev.unherd.com/';
    } else {
      url = 'https://botapi.unherd.com/';
    }
    try {
      const response = await axios.post(
        url,
        { 
          query_text: message,
          logged_in_user_info: logged_in_info
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.result; // Adjust based on the structure of your Lambda response
    } catch (error) {
      console.error("Error:", error);
      return "Sorry, I'm having trouble understanding you right now. Please try again later. 🤖";
    }
  },
};
export default API;
